import { Observable, ReplaySubject } from 'rxjs';
import { IDtoSurveyTemplate, IDtoSurveyInstance } from '../../api/dtos/surveys';
import {
  BaseRepository,
  RepositoryResponse,
  RepositoryResponseGeneric,
} from '../abstractions/repositories';

export abstract class SurveyRepository extends BaseRepository {
  //#region Load Survey by Id
  public abstract loadSurvey(id: number, lang: string): void

  protected loadSurveyResponseSubject = new ReplaySubject<RepositoryResponse<IDtoSurveyTemplate>>();

  public loadSurveyResponse$(): Observable<RepositoryResponse<IDtoSurveyTemplate>> {
    return this.loadSurveyResponseSubject.asObservable();
  }

  public setLoadSurveyResponse(tmp: RepositoryResponse<IDtoSurveyTemplate>) {
    this.loadSurveyResponseSubject.next(tmp);
  }

  //#endregion


  //#region Load All Surveys
  public abstract loadAllSurveys(lang: string): void

  protected loadAllSurveysResponseSubject = new ReplaySubject<RepositoryResponse<IDtoSurveyTemplate[]>>();

  public loadAllSurveysResponse$(): Observable<RepositoryResponse<IDtoSurveyTemplate[]>> {
    return this.loadAllSurveysResponseSubject.asObservable();
  }

  public setLoadAllSurveysResponse(tmp: RepositoryResponse<IDtoSurveyTemplate[]>) {
    this.loadAllSurveysResponseSubject.next(tmp);
  }

  //#endregion


  //#region Save Survey
  public abstract saveSurvey(lang: string, template: IDtoSurveyTemplate): void

  protected saveSurveyResponseSubject = new ReplaySubject<RepositoryResponseGeneric>();

  public saveSurveyResponse$(): Observable<RepositoryResponseGeneric> {
    return this.saveSurveyResponseSubject.asObservable();
  }

  public setSaveSurveyResponse(tmp: RepositoryResponseGeneric) {
    this.saveSurveyResponseSubject.next(tmp);
  }

  //#endregion


  //#region Save Survey Filled
  public abstract saveSurveyFilled(lang: string, template: IDtoSurveyInstance): void

  protected saveSurveyFilledResponseSubject = new ReplaySubject<RepositoryResponseGeneric>();

  public saveSurveyFilledResponse$(): Observable<RepositoryResponseGeneric> {
    return this.saveSurveyFilledResponseSubject.asObservable();
  }

  public setSaveSurveyFilledResponse(tmp: RepositoryResponseGeneric) {
    this.saveSurveyFilledResponseSubject.next(tmp);
  }

  //#endregion


  //#region Load Filled Survey
  public abstract loadFilledSurvey(id: number, lang: string, loadAnswers: boolean): void

  protected loadFilledSurveyResponseSubject = new ReplaySubject<RepositoryResponse<IDtoSurveyInstance>>();

  public loadFilledSurveyResponse$(): Observable<RepositoryResponse<IDtoSurveyInstance>> {
    return this.loadFilledSurveyResponseSubject.asObservable();
  }

  public setLoadFilledSurveyResponse(tmp: RepositoryResponse<IDtoSurveyInstance>) {
    this.loadFilledSurveyResponseSubject.next(tmp);
  }

  //#endregion


  //#region Load All Filled Surveys
  public abstract loadAllFilledSurveys(lang: string, loadAnswers: boolean, status: string): void

  protected loadAllFilledSurveysResponseSubject = new ReplaySubject<RepositoryResponse<IDtoSurveyInstance[]>>();

  public loadAllFilledSurveysResponse$(): Observable<RepositoryResponse<IDtoSurveyInstance[]>> {
    return this.loadAllFilledSurveysResponseSubject.asObservable();
  }

  public setLoadAllFilledSurveysResponse(tmp: RepositoryResponse<IDtoSurveyInstance[]>) {
    this.loadAllFilledSurveysResponseSubject.next(tmp);
  }

  //#endregion


  //#region Set Survey State
  public abstract setSurveyState(id: number, lang: string, stateToAssign: string): void

  protected setSurveyStateResponseSubject = new ReplaySubject<RepositoryResponseGeneric>();

  public setSurveyStateResponse$(): Observable<RepositoryResponseGeneric> {
    return this.setSurveyStateResponseSubject.asObservable();
  }

  public setSetSurveyStateResponse(tmp: RepositoryResponseGeneric) {
    this.setSurveyStateResponseSubject.next(tmp);
  }

  //#endregion
}
