import { BaseRepository, RepositoryResponse, RepositoryResponseGeneric } from '../abstractions/repositories';
import { Observable, ReplaySubject } from 'rxjs';
import { StripeElements } from '@stripe/stripe-js';
import {
  DtoStripeCustomerGetRequest,
  DtoStripeCustomerGetResponse,
  DtoStripeSetupIntentGetRequest,
  DtoStripeSetupIntentGetResponse,
  DtoStripeSubscriptionApplyAccessKeyRequest,
  DtoStripeSubscriptionApplyAccessKeyResponse,
  IDtoStripeData,
  IDtoStripePaymentMethodUpdateFinalizeRequest,
  IDtoStripePaymentMethodUpdateFinalizeResponse,
  DtoStripeSubscriptionCreateRequest,
  DtoStripeSubscriptionCreateResponse,
} from '../../api/dtos/stripe';

export abstract class StripeRepository extends BaseRepository {

  //#region Create Subscription 3D Secure
  // protected subscriptionCreateResponse
  //   = new ReplaySubject<RepositoryResponse<{
  //   CustomerId: string;
  //   PlanId: string;
  //   PlanPrice: number;
  //   CouponId: string;
  //   ClientSecret: string;
  // }>>();
  //
  // public subscriptionCreateResponse$(): Observable<RepositoryResponse<{
  //   CustomerId: string;
  //   PlanId: string;
  //   PlanPrice: number;
  //   CouponId: string;
  //   ClientSecret: string;
  // }>> {
  //   return this.subscriptionCreateResponse.asObservable();
  // }
  //
  // public setSubscriptionCreateResponse(tmp: RepositoryResponse<{
  //   CustomerId: string;
  //   PlanId: string;
  //   PlanPrice: number;
  //   CouponId: string;
  //   ClientSecret: string;
  // }>) {
  //   this.subscriptionCreateResponse.next(tmp);
  // }
  //
  // public abstract subscriptionCreate(dto: {
  //   AccessKey: string,
  //   Email: string,
  //   FriendCode: number
  // }): void;

  //#endregion

  //#region Payment

  public abstract paymentConfirm(stripeElements: StripeElements, returnUrl: string, clientSecret: string | undefined): void;

  protected paymentConfirmResponse = new ReplaySubject<RepositoryResponse<{ error: any }>>();

  public paymentConfirmResponse$(): Observable<RepositoryResponse<{ error: any }>> {
    return this.paymentConfirmResponse.asObservable();
  }

  public setPaymentConfirmResponse(tmp: RepositoryResponse<{ error: any }>) {
    this.paymentConfirmResponse.next(tmp);
  }

  //#endregion

  //#region SetupIntent
  protected setupIntentGetResponse =
    new ReplaySubject<RepositoryResponse<DtoStripeSetupIntentGetResponse>>();

  public setupIntentGetResponse$(): Observable<RepositoryResponse<DtoStripeSetupIntentGetResponse>> {
    return this.setupIntentGetResponse.asObservable();
  }

  public setSetupIntentGetResponse(tmp: RepositoryResponse<DtoStripeSetupIntentGetResponse>) {
    this.setupIntentGetResponse.next(tmp);
  }

  public abstract setupIntentGet(dto: DtoStripeSetupIntentGetRequest): void;


  protected setupIntentConfirmResponse = new ReplaySubject<RepositoryResponse<{ error: any }>>();

  public abstract setupIntentConfirm(stripeElements: StripeElements, returnUrl: string, clientSecret: string | undefined): void;

  public setupIntentConfirmResponse$(): Observable<RepositoryResponse<{ error: any }>> {
    return this.setupIntentConfirmResponse.asObservable();
  }

  public setSetupIntentConfirmResponse(tmp: RepositoryResponse<{ error: any }>) {
    this.setupIntentConfirmResponse.next(tmp);
  }

  //#endregion


  //#region Customer
  protected customerGetResponse =
    new ReplaySubject<RepositoryResponse<DtoStripeCustomerGetResponse>>();

  public customerGetResponse$(): Observable<RepositoryResponse<DtoStripeCustomerGetResponse>> {
    return this.customerGetResponse.asObservable();
  }

  public setCustomerGetResponse(tmp: RepositoryResponse<DtoStripeCustomerGetResponse>) {
    this.customerGetResponse.next(tmp);
  }

  public abstract customerGet(dto: DtoStripeCustomerGetRequest): void;

  //#endregion

  //#region Subscription
  protected subscriptionCreateResponse =
    new ReplaySubject<RepositoryResponse<DtoStripeSubscriptionCreateResponse>>();

  public subscriptionCreateResponse$(): Observable<RepositoryResponse<DtoStripeSubscriptionCreateResponse>> {
    return this.subscriptionCreateResponse.asObservable();
  }

  public setSubscriptionCreateResponse(tmp: RepositoryResponse<DtoStripeSubscriptionCreateResponse>) {
    this.subscriptionCreateResponse.next(tmp);
  }

  public abstract subscriptionCreate(dto: DtoStripeSubscriptionCreateRequest): void;


  protected subscriptionApplyAccessKeyResponse =
    new ReplaySubject<RepositoryResponse<DtoStripeSubscriptionApplyAccessKeyResponse>>();

  public subscriptionApplyAccessKeyResponse$(): Observable<RepositoryResponse<DtoStripeSubscriptionApplyAccessKeyResponse>> {
    return this.subscriptionApplyAccessKeyResponse.asObservable();
  }

  public setSubscriptionApplyAccessKeyResponse(tmp: RepositoryResponse<DtoStripeSubscriptionApplyAccessKeyResponse>) {
    this.subscriptionApplyAccessKeyResponse.next(tmp);
  }

  public abstract subscriptionApplyAccessKey(dto: DtoStripeSubscriptionApplyAccessKeyRequest): void;

  //#endregion

  //#region UpdatePaymentMethodFinalize
  protected updatePaymentMethodFinalizeResponse = new ReplaySubject<RepositoryResponse<IDtoStripePaymentMethodUpdateFinalizeResponse>>();

  public updatePaymentMethodFinalizeResponse$(): Observable<RepositoryResponse<IDtoStripePaymentMethodUpdateFinalizeResponse>> {
    return this.updatePaymentMethodFinalizeResponse.asObservable();
  }

  public setUpdatePaymentMethodFinalizeResponse(tmp: RepositoryResponse<IDtoStripePaymentMethodUpdateFinalizeResponse>) {
    this.updatePaymentMethodFinalizeResponse.next(tmp);
  }

  public abstract updatePaymentMethodFinalize(dto: IDtoStripePaymentMethodUpdateFinalizeRequest): void;

  //#endregion


  //#region Stripe Info
  public abstract getUserStripeInfo(lang: string): void;

  protected userStripeInfoResponse = new ReplaySubject<RepositoryResponse<IDtoStripeData>>();

  public userStripeInfoResponse$(): Observable<RepositoryResponse<IDtoStripeData>> {
    return this.userStripeInfoResponse.asObservable();
  }

  public setStripeInfoResponse(tmp: RepositoryResponse<IDtoStripeData>) {
    this.userStripeInfoResponse.next(tmp);
  }

  //#endregion

  //#region Unsubscribe
  protected userUnsubscribeResponse = new ReplaySubject<RepositoryResponseGeneric>();

  public userUnsubscribeResponse$(): Observable<RepositoryResponseGeneric> {
    return this.userUnsubscribeResponse.asObservable();
  }

  public setUserUnsubscribeResponse(tmp: RepositoryResponseGeneric) {
    this.userUnsubscribeResponse.next(tmp);
  }

  public abstract userUnsubscribe(): void;

  //#endregion

  //#region Resubscribe
  protected userResubscribeResponse = new ReplaySubject<RepositoryResponseGeneric>();

  public userResubscribeResponse$(): Observable<RepositoryResponseGeneric> {
    return this.userResubscribeResponse.asObservable();
  }

  public setUserResubscribeResponse(tmp: RepositoryResponseGeneric) {
    this.userResubscribeResponse.next(tmp);
  }

  public abstract userResubscribe(): void;

  //#endregion
}
