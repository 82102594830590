import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BookmarkDto } from '../dtos/uok-detail';
import { SuggestionEmailDto } from '../dtos/uok-detail/suggestion-email-dto.interface';

@Injectable({
  providedIn: 'root',
})
export class UokDetailApiService {
  constructor(private httpClient: HttpClient) {
  }

  public getHeader(id: number, lang: string) {
    return this.httpClient.get(`${environment.webApiV1}${lang}/uok/detail/header?id=${id}`);
  }

  public getUserInfo(id: number, lang: string) {
    return this.httpClient.get(`${environment.webApiV1}${lang}/uok/detail/user-info?id=${id}`);
  }

  public getLockInfo(id: number, lang: string) {
    return this.httpClient.get(`${environment.webApiV1}${lang}/uok/detail/lock-info?id=${id}`);
  }

  public getContentInfo(id: number, lang: string) {
    return this.httpClient.get(`${environment.webApiV1}${lang}/uok/detail/content-info?id=${id}`);
  }

  public getBookmarkState(id: number) {
    return this.httpClient.get(`${environment.webApiV1}uok/bookmark/get-state?uokId=${id}`);
  }

  public setBookmarkState(dto: BookmarkDto) {
    return this.httpClient.post(`${environment.webApiV1}uok/bookmark/set-state`, JSON.stringify(dto));
  }

  public activateUok(uokId: number) {
    return this.httpClient.put(`${environment.webApiV1}uok/version/uokactivate/${uokId}`, null);
  }

  public disableUok(uokId: number) {
    return this.httpClient.put(`${environment.webApiV1}uok/version/uokdisable/${uokId}`, null);
  }

  public signalUok(uokId: number) {
    return this.httpClient.put(`${environment.webApiV1}uok/version/uoksignal/${uokId}`, null);
  }

  public sendSuggestionEmail(dto: SuggestionEmailDto) {
    return this.httpClient.post(`${environment.webApiV1}en/uok/detail/send-suggestion-email`, JSON.stringify(dto));
  }
}
