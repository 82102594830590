import { UtilityRepository } from '../../core/repositories';
import { UtilityApiService } from '../../api/services';
import { takeUntil } from 'rxjs/operators';
import { RepositoryResponse } from '../../core/abstractions/repositories';
import { HttpUtils } from '../../core/utils';
import { ICultureDefinitionDto } from '../../api/dtos/utility/culture-definition-dto.interface';

export class UtilityRepositoryApi extends UtilityRepository {
  constructor(private utilityApi: UtilityApiService) {
    super();
  }

  public override loadCultureDefinitions(): void {
    this.utilityApi.getCultureDefinitions()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          //console.log(data);
          const body = data as ICultureDefinitionDto[];
          const tmp: RepositoryResponse<ICultureDefinitionDto[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'ICultureDefinitionDto[] is null' : 'ICultureDefinitionDto[] received',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setCultureDefinitionsResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<ICultureDefinitionDto[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setCultureDefinitionsResponse(tmp);
        },
      });
  }
}
