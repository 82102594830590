import {AccessKeyRepository} from "../../core/repositories/access-key-repository.class";
import {RepositoryResponse} from "../../core/abstractions/repositories";
import {IDtoAccessKey} from "../../api/dtos/user";
import { environment } from '../../../environments/environment';

export class AccessKeyRepositoryMock extends AccessKeyRepository {
  generateAccessKeys(friendCode: number, amount: number, discountDays: number, productionLot: string, activableToDay: number, activableToMonth: number, activableToYear: number): void {
  }

  getAccessKeyInfo(key: string): void {
  }

  getAccessKeys(): void {
    this.setIsUpdating(true);
    this.setIsUpdating(false);

    const tmp: RepositoryResponse<IDtoAccessKey[]> = {
      dto: [
        {
          SystemName: 'no-name',
          OperatorId: 20,
          DbId: 10,
          CultureName: environment.defaultCulture,
          Code: 'AAAAAAAAAAAAAAA',
          State: 'active',
          // UtcValidFrom?: Date;
          // UtcValidTo?: Date;
          DiscountDays: 300,
          // FriendCode?: number;
          IsValid: true,
          UtcDateLock: new Date(2023, 1, 2),
          UtcDateActive: new Date(2023, 2, 2),
          UtcDateExpire: new Date(2023, 3, 2)
        }
      ],
      info: {
        level: 0,
        messages: ['UserDto received']
      }
    };

    this.setGetAccessKeysStateResponse(tmp)
  }

  lockAccessKey(key: string): void {
  }

}
