import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { TranslateConfig } from './core/services/translate-config.class';
import { AccountState } from './core/state/account';
import { UokDetailState } from './core/state/detail';
import { DraftState } from './core/state/draft';
import { HistoryState } from './core/state/history';
import { ProductState } from './core/state/product';
import { SearchEngineState } from './core/state/search-engine';
import { SignupState } from './core/state/signup';
import { SurveyState } from './core/state/survey';
import { UseCasesState } from './core/state/use-cases';
import { UserState } from './core/state/user';
import { ServiceLocator } from './core/utils';
import { serviceProviders } from './services';
import { httpInterceptorProviders } from './api/http-interceptors';
import { DatePipe } from '@angular/common';
import { NgxStripeModule } from 'ngx-stripe';
import { OverlayModule } from '@angular/cdk/overlay';
import { MainMenuState } from './core/state/main-menu/main-menu-state';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { DirectivesModule } from './directives/directives.module';
import { DraftStorageEngine } from './core/db/DraftStorageEngine';
import { DraftStateIndexeddb } from './core/state/draft-state/draft-state-indexeddb';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { provideMatomo, withRouter, withRouterInterceptors } from 'ngx-matomo-client';
import { StripeState } from './core/state/stripe';
import { LinkEditorState } from './core/state/link-editor/link-editor-state';
import { MatomoUserIdInterceptor } from './matomo-user-id-interceptor';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxTurnstileModule } from 'ngx-turnstile';
import { UtilityState } from './core/state/utility/utility-state.class';

export function HttpTranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    NgxStripeModule.forRoot(environment.stripe.key),
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: TranslateConfig.defaultLanguage,
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpTranslationLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    NgxsModule.forRoot([
      AccountState,
      UserState,
      UseCasesState,
      ProductState,
      SurveyState,
      SearchEngineState,
      UokDetailState,
      DraftState,
      SignupState,
      HistoryState,
      MainMenuState,
      StripeState,
      LinkEditorState,
      UtilityState
    ]),
    NgxsStoragePluginModule.forRoot({
      key: [
        'user',
        'account',
        'products',
        'history',
        'surveys',
        'mainMenu',
        'signup',
        'stripe',
        'linkEditor',
        'utility'
      ],
      storage: StorageOption.LocalStorage,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    OverlayModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxTurnstileModule,
  ],
  providers: [
    provideMatomo(
      {
        trackerUrl: environment.matomo.trackerUrl,
        siteId: environment.matomo.siteId,
      },
      withRouter(),
      withRouterInterceptors([
        MatomoUserIdInterceptor,
      ]),
    ),
    provideNgxMask(),
    DatePipe,
    httpInterceptorProviders,
    serviceProviders,
    { provide: DraftStorageEngine, useClass: DraftStorageEngine },
    { provide: DraftStateIndexeddb, useClass: DraftStateIndexeddb },
  ],
  exports: [
    TranslateModule,
    DirectivesModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {    // Create global Service Injector.
    ServiceLocator.injector = this.injector;
  }
}
