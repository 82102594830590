import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UtilityStateModel } from './utility-state-model.interface';
import { CultureDefinitionsLoaded } from './utility-actions';

@State<UtilityStateModel>({
  name: 'utility',
  defaults: {
    cultureDefinitions: [],
  },
})
@Injectable()
export class UtilityState {
  @Selector()
  static cultureDefinitions(state: UtilityStateModel) {
    return state.cultureDefinitions;
  }

  @Action(CultureDefinitionsLoaded)
  surveyTemplateLoad(ctx: StateContext<UtilityStateModel>, action: CultureDefinitionsLoaded) {
    ctx.patchState({
      cultureDefinitions: action.definitions,
    });
  }
}
