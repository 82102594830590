/** Collects all the names of the DTO.
 *  They have to match with the ones generated by
 *  the webApi
 */
export class DtoSystemNames {
    // users
    public static readonly UserOperator = 'Dto.Usr.Operator';
    public static readonly UserCustomer = 'Dto.Usr.Customer';
    // stripe
    public static readonly StripeData = 'Dto.Stripe.Data';
    // search engine
    public static readonly SearchEngineItem = 'Dto.SrcEng.Itm';
    // tags
    public static readonly Tag = 'Dto.Tag';
    // Conversation
    public static readonly Conversation = 'Dto.Conversation';

    // Uok
    public static readonly UokMeridian = 'Dto.Uok.Meridian';
    public static readonly UokVocabulary = 'Dto.Uok.Vocabulary';
    public static readonly UokAcuPoint = 'Dto.Uok.AcuPoint';
    public static readonly UokDigitalMode = 'Dto.Uok.DigitalMode';
    public static readonly UokFormat = 'Dto.Uok.Format';
    public static readonly UokMuscle = 'Dto.Uok.Muscle';
    public static readonly UokMuscleTest = 'Dto.Uok.MuscleTest';
    public static readonly UokTechnique = 'Dto.Uok.Technique';
    public static readonly UokGroup = 'Dto.Uok.Group';
    public static readonly UokReference = 'Dto.Uok.Reference';
    public static readonly UokAuthor = 'Dto.Uok.Author';
    public static readonly UokFrequency = 'Dto.Uok.Frequency';
    // public static readonly UokAction = 'Dto.Uok.Action';
    // public static readonly UokGuide = 'Dto.Uok.Guide';
    public static readonly UokBone = 'Dto.Uok.Bone';
    public static readonly UokJoint = 'Dto.Uok.Joint';
    public static readonly UokNeuroLymphatic = 'Dto.Uok.Neurolymphatic';
    public static readonly UokNeuroVascular = 'Dto.Uok.Neurovascular';
    public static readonly UokAnatomy = 'Dto.Uok.Anatomy';

    public static GetUoks(): string[] {
        return [
            'Dto.Uok.Meridian',
            'Dto.Uok.Vocabulary',
            'Dto.Uok.AcuPoint',
            'Dto.Uok.DigitalMode',
            'Dto.Uok.Format',
            'Dto.Uok.Muscle',
            'Dto.Uok.MuscleTest',
            'Dto.Uok.Technique',
            'Dto.Uok.Group',
            'Dto.Uok.Reference',
            'Dto.Uok.Author',
            'Dto.Uok.Frequency',
            // 'Dto.Uok.Action',
            // 'Dto.Uok.Guide',
            'Dto.Uok.Bone',
            'Dto.Uok.Joint',
            'Dto.Uok.Neurolymphatic',
            'Dto.Uok.Neurovascular',
            'Dto.Uok.Anatomy',
        ];
    }
}
