import { Observable, ReplaySubject } from 'rxjs';
import { BaseRepository, RepositoryResponse } from '../abstractions/repositories';
import { ICultureDefinitionDto } from '../../api/dtos/utility/culture-definition-dto.interface';

export abstract class UtilityRepository extends BaseRepository {
  //#region Uok Header
  public abstract loadCultureDefinitions(): void

  protected loadCultureDefinitionsResponseSubject = new ReplaySubject<RepositoryResponse<ICultureDefinitionDto[]>>();

  public cultureDefinitionsResponse$(): Observable<RepositoryResponse<ICultureDefinitionDto[]>> {
    return this.loadCultureDefinitionsResponseSubject.asObservable();
  }

  public setCultureDefinitionsResponse(tmp: RepositoryResponse<ICultureDefinitionDto[]>) {
    this.loadCultureDefinitionsResponseSubject.next(tmp);
  }

  //#endregion
}
