import { Observable, ReplaySubject } from 'rxjs';
import {
  BookmarkDto,
  UokDetailContentInfoDto,
  UokDetailHeaderDto,
  UokDetailLockInfoDto,
  UokDetailUserInfoDto,
} from '../../api/dtos/uok-detail';
import {
  BaseRepository,
  RepositoryResponse,
  RepositoryResponseGeneric,
} from '../abstractions/repositories';
import { SuggestionEmailDto } from '../../api/dtos/uok-detail/suggestion-email-dto.interface';

export abstract class UokDetailRepository extends BaseRepository {
  //#region Uok Header
  public abstract loadUokHeader(uokId: number, lang: string): void

  protected loadUokHeaderResponseSubject = new ReplaySubject<RepositoryResponse<UokDetailHeaderDto>>();

  public uokHeaderResponse$(): Observable<RepositoryResponse<UokDetailHeaderDto>> {
    return this.loadUokHeaderResponseSubject.asObservable();
  }

  public setUokHeaderResponse(tmp: RepositoryResponse<UokDetailHeaderDto>) {
    this.loadUokHeaderResponseSubject.next(tmp);
  }

  //#endregion

  //#region Uok User Info
  public abstract loadUokUserInfo(uokId: number, lang: string): void

  protected uokUserInfoResponseSubject = new ReplaySubject<RepositoryResponse<UokDetailUserInfoDto>>();

  public uokUserInfoResponse$(): Observable<RepositoryResponse<UokDetailUserInfoDto>> {
    return this.uokUserInfoResponseSubject.asObservable();
  }

  public setUokUserInfoResponse(tmp: RepositoryResponse<UokDetailUserInfoDto>) {
    this.uokUserInfoResponseSubject.next(tmp);
  }

  //#endregion

  //#region Uok Lock Info
  public abstract loadUokLockInfo(uokId: number, lang: string): void

  protected uokLockInfoResponseSubject = new ReplaySubject<RepositoryResponse<UokDetailLockInfoDto>>();

  public uokLockInfoResponse$(): Observable<RepositoryResponse<UokDetailLockInfoDto>> {
    return this.uokLockInfoResponseSubject.asObservable();
  }

  public setUokLockInfoResponse(tmp: RepositoryResponse<UokDetailLockInfoDto>) {
    this.uokLockInfoResponseSubject.next(tmp);
  }

  //#endregion

  //#region Uok Content Info
  public abstract loadUokContentInfo(uokId: number, lang: string): void

  protected uokContentInfoResponseSubject = new ReplaySubject<RepositoryResponse<UokDetailContentInfoDto>>();

  public uokContentInfoResponse$(): Observable<RepositoryResponse<UokDetailContentInfoDto>> {
    return this.uokContentInfoResponseSubject.asObservable();
  }

  public setUokContentInfoResponse(tmp: RepositoryResponse<UokDetailContentInfoDto>) {
    this.uokContentInfoResponseSubject.next(tmp);
  }

  //#endregion

  //#region Uok Bookmark Get State
  public abstract loadUokBookmarkState(uokId: number): void

  protected loadUokBookmarkStateResponseSubject = new ReplaySubject<RepositoryResponse<BookmarkDto>>();

  public loadUokBookmarkStateResponse$(): Observable<RepositoryResponse<BookmarkDto>> {
    return this.loadUokBookmarkStateResponseSubject.asObservable();
  }

  public setLoadUokBookmarkStateResponse(tmp: RepositoryResponse<BookmarkDto>) {
    this.loadUokBookmarkStateResponseSubject.next(tmp);
  }

  //#endregion

  //#region Uok Bookmark Set State
  public abstract setUokBookmarkState(dto: BookmarkDto): void

  protected setUokBookmarkStateResponseSubject = new ReplaySubject<RepositoryResponseGeneric>();

  public setUokBookmarkStateResponse$(): Observable<RepositoryResponseGeneric> {
    return this.setUokBookmarkStateResponseSubject.asObservable();
  }

  public setSetUokBookmarkStateResponse(tmp: RepositoryResponseGeneric) {
    this.setUokBookmarkStateResponseSubject.next(tmp);
  }

  //#endregion

  //#region Send uok suggestion email
  public abstract sendUokSuggestion(dto: SuggestionEmailDto): void

  protected setSendUokSuggestionResponseSubject = new ReplaySubject<RepositoryResponseGeneric>();

  public sendUokSuggestionResponse$(): Observable<RepositoryResponseGeneric> {
    return this.setSendUokSuggestionResponseSubject.asObservable();
  }

  public setUokSuggestionResponse(tmp: RepositoryResponseGeneric) {
    this.setSendUokSuggestionResponseSubject.next(tmp);
  }

  //#endregion
}
