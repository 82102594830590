import { Observable, ReplaySubject } from 'rxjs';
import { IDtoUokLink, IDtoReferredInfo, IDtoUokLinksDefinition } from '../../api/dtos/uok-link';
import { BaseRepository } from '../abstractions/repositories';
import { RepositoryResponse, RepositoryResponseGeneric } from '../abstractions/repositories';

export abstract class UokLinkRepository extends BaseRepository {
  //#region load Uok Links Definition
  public abstract loadUokLinksDefinition(dtoUokName: string, lang: string): void

  protected loadUokLinksDefinitionResponseSubject = new ReplaySubject<RepositoryResponse<IDtoUokLinksDefinition>>();

  public loadUokLinksDefinitionResponse$(): Observable<RepositoryResponse<IDtoUokLinksDefinition>> {
    return this.loadUokLinksDefinitionResponseSubject.asObservable();
  }

  public setLoadUokLinksDefinitionResponse(tmp: RepositoryResponse<IDtoUokLinksDefinition>) {
    this.loadUokLinksDefinitionResponseSubject.next(tmp);
  }

  //#endregion

  //#region load Uok Links
  public abstract loadUokLinks(id: number, lang: string): void

  protected loadUokLinksResponseSubject = new ReplaySubject<RepositoryResponse<IDtoUokLink[]>>();

  public loadUokLinksResponse$(): Observable<RepositoryResponse<IDtoUokLink[]>> {
    return this.loadUokLinksResponseSubject.asObservable();
  }

  public setLoadUokLinksResponse(tmp: RepositoryResponse<IDtoUokLink[]>) {
    this.loadUokLinksResponseSubject.next(tmp);
  }

  //#endregion

  //#region load Uok Links Referred
  public abstract loadUokLinksReferred(id: number, lang: string): void

  protected loadUokLinksReferredResponseSubject = new ReplaySubject<RepositoryResponse<IDtoReferredInfo[]>>();

  public loadUokLinksReferredResponse$(): Observable<RepositoryResponse<IDtoReferredInfo[]>> {
    return this.loadUokLinksReferredResponseSubject.asObservable();
  }

  public setLoadUokLinksReferredResponse(tmp: RepositoryResponse<IDtoReferredInfo[]>) {
    this.loadUokLinksReferredResponseSubject.next(tmp);
  }

  //#endregion

  //#region load Knowledge Tree
  public abstract loadKnowledgeTree(id: number, lang: string): void

  protected loadKnowledgeTreeResponseSubject = new ReplaySubject<RepositoryResponse<IDtoReferredInfo[]>>();

  public loadKnowledgeTreeResponse$(): Observable<RepositoryResponse<IDtoReferredInfo[]>> {
    return this.loadKnowledgeTreeResponseSubject.asObservable();
  }

  public setLoadKnowledgeTreeResponse(tmp: RepositoryResponse<IDtoReferredInfo[]>) {
    this.loadKnowledgeTreeResponseSubject.next(tmp);
  }

  //#endregion

  //#region Save Uok Links
  public abstract saveUokLinks(links: IDtoUokLink[], lang: string): void

  protected saveUokLinksResponseSubject = new ReplaySubject<RepositoryResponseGeneric>();

  public saveUokLinksResponse$(): Observable<RepositoryResponseGeneric> {
    return this.saveUokLinksResponseSubject.asObservable();
  }

  public setSaveUokLinksResponse(tmp: RepositoryResponseGeneric) {
    this.saveUokLinksResponseSubject.next(tmp);
  }

  //#endregion
}
