import { DtoSystemNames, UtilsDto } from '../../../../../core/utils';
import {
  lCharacters, lClientPosition, lElements, lEnergyLevels, lIsDeep, lKinds, lKinds2nd, lLimbs, lTime,
} from '../../../../../core/utils/meridian-enums';
import { IDtoDraft } from '../../../../../api/dtos/uok';
import { Store } from '@ngxs/store';
import { UtilityState } from '../../../../../core/state/utility/utility-state.class';
import { environment } from '../../../../../../environments/environment';

export class DraftUtils {
  constructor(private store: Store) {
  }

  static getCategories(culture: string): any {
    const availableUoks: any[] = [];
    DtoSystemNames.GetUoks()
      .sort((a, b) => a.localeCompare(b))
      .forEach((d) => {
        availableUoks.push({
          label: UtilsDto.GetLabel(d, culture),
          value: d,
          iconName: UtilsDto.GetRouteLinkIcon(d),
        });
      });

    return availableUoks;
  }

  static getCategoryByName(categoryName: string, culture: string): any {
    return this.getCategories(culture).find((x: any) => x.value == categoryName);
  }

  static getKindsList(): string[] {
    return Object.values(lKinds).filter(value => typeof value === 'string') as string[];
  }

  static getKinds(): any {
    const tmp: any[] = [];
    Object.keys(lKinds).filter((x: any) => !isNaN(x)).sort().forEach((i: unknown) => {
      tmp.push({
        label: lKinds[i as number],
        value: i as number,
      });
    });
    return tmp;
  }

  static getKindByValue(value: number): any {
    return this.getKinds().find((x: any) => x.value == value);
  }

  static getKinds2ndList(): string[] {
    return Object.values(lKinds2nd).filter(value => typeof value === 'string') as string[];
  }

  static getKinds2nd(): any {
    const tmp: any[] = [];
    Object.keys(lKinds2nd).filter((x: any) => !isNaN(x)).sort().forEach((i: unknown) => {
      tmp.push({
        label: lKinds2nd[i as number],
        value: i as number,
      });
    });
    return tmp;
  }

  static getKinds2ndByValue(value: number): any {
    return this.getKinds2nd().find((x: any) => x.value == value);
  }

  static getCharactersList(): string[] {
    return Object.values(lCharacters).filter(value => typeof value === 'string') as string[];
  }

  static getCharacters(): any {
    const tmp: any[] = [];
    Object.keys(lCharacters).filter((x: any) => !isNaN(x)).sort().forEach((i: unknown) => {
      tmp.push({
        label: lCharacters[i as number],
        value: i as number,
      });
    });
    return tmp;
  }

  static getCharactersByValue(value: number): any {
    return this.getCharacters().find((x: any) => x.value == value);
  }

  static getTimeList(): string[] {
    return Object.values(lTime).filter(value => typeof value === 'string') as string[];
  }

  static getTime(): any {
    const tmp: any[] = [];
    Object.keys(lTime).filter((x: any) => !isNaN(x)).sort().forEach((i: unknown) => {
      tmp.push({
        label: lTime[i as number],
        value: i as number,
      });
    });
    return tmp;
  }

  static getTimeByValue(value: number): any {
    return this.getTime().find((x: any) => x.value == value);
  }

  static getElementsList(): string[] {
    return Object.values(lElements).filter(value => typeof value === 'string') as string[];
  }

  static getElements(): any {
    const tmp: any[] = [];
    Object.keys(lElements).filter((x: any) => !isNaN(x)).sort().forEach((i: unknown) => {
      tmp.push({
        label: lElements[i as number],
        value: i as number,
      });
    });
    return tmp;
  }

  static getElementsByValue(value: number): any {
    return this.getElements().find((x: any) => x.value == value);
  }

  static getEnergyLevelsList(): string[] {
    return Object.values(lEnergyLevels).filter(value => typeof value === 'string') as string[];
  }

  static getEnergyLevels(): any {
    const tmp: any[] = [];
    Object.keys(lEnergyLevels).filter((x: any) => !isNaN(x)).sort().forEach((i: unknown) => {
      tmp.push({
        label: lEnergyLevels[i as number],
        value: i as number,
      });
    });
    return tmp;
  }

  static getEnergyLevelsByValue(value: number): any {
    return this.getEnergyLevels().find((x: any) => x.value == value);
  }

  static getLimbsList(): string[] {
    return Object.values(lLimbs).filter(value => typeof value === 'string') as string[];
  }

  static getLimbs(): any {
    const tmp: any[] = [];
    Object.keys(lLimbs).filter((x: any) => !isNaN(x)).sort().forEach((i: unknown) => {
      tmp.push({
        label: lLimbs[i as number],
        value: i as number,
      });
    });
    return tmp;
  }

  static getLimbsByValue(value: number): any {
    return this.getLimbs().find((x: any) => x.value == value);
  }

  static getIsDeep(): any {
    const tmp: any[] = [];
    Object.keys(lIsDeep).filter((x: any) => !isNaN(x)).sort().forEach((i: unknown) => {
      tmp.push({
        label: lIsDeep[i as number],
        value: i as number,
      });
    });
    return tmp;
  }

  static getIsDeepByValue(value: number): any {
    return this.getIsDeep().find((x: any) => x.value == value);
  }

  static getClientPosition(): any[] {
    const tmp: any[] = [];
    Object.keys(lClientPosition).filter((x: any) => !isNaN(x)).sort().forEach((i: unknown) => {
      tmp.push({
        label: lClientPosition[i as number],
      });
    });

    return tmp;
  }

  static getClientPositionsForDto(selectedPositions: string[]): number {
    if (!selectedPositions || selectedPositions.length === 0) {
      return 0;
    }

    let flagAsNum = 0;

    selectedPositions.forEach((sp) => {
      const idx = Object.values(lClientPosition).filter((v) => isNaN(Number(v)))
        .findIndex((p) => p === sp);
      if (idx >= 0) {
        const mask = Math.pow(2, idx);
        // tslint:disable-next-line:no-bitwise
        flagAsNum |= mask;
      }
    });

    // console.debug("Valore di flag:" + flagAsNum);
    return flagAsNum;
  }

  static getClientPositionsForFormControl(selectedPositions: number): string[] {
    if (!selectedPositions || selectedPositions === 0) {
      return [];
    }

    const tmp: string[] = [];
    const lClientP = Object.values(lClientPosition).filter((v) => isNaN(Number(v)));

    lClientP.forEach((cp) => {
      const idx = lClientP.indexOf(cp);
      if (idx >= 0) {
        const mask = Math.pow(2, idx);
        // tslint:disable-next-line:no-bitwise
        const isSelected = (selectedPositions & mask) !== 0; // (idx & selectedPositions) === idx;
        if (isSelected) {
          tmp.push(cp as string);
        }
      }
    });
    return tmp;
  }

  static isModifiable(culture: string): any[] {
    switch (culture) {
      case 'es':
        return [
          {
            label: 'Modificable',
            value: true,
          },
          {
            label: 'No Modificable',
            value: false,
          },
        ];
      case  'it':
        return [
          {
            label: 'Modificabile',
            value: true,
          },
          {
            label: 'Non Modificabile',
            value: false,
          },
        ];
      default:
        return [
          {
            label: 'Modifiable',
            value: true,
          },
          {
            label: 'Non Modifiable',
            value: false,
          },
        ];
    }
  }


  draftDtoToFormData(dto: IDtoDraft): any {
    const definitions = this.store.selectSnapshot(UtilityState.cultureDefinitions);
    const culture = definitions.find(d => d.CultureName === dto.CultureName);
    let formCulture = {
      value: 'en',
      label: 'English',
      iconName: 'f-us',
    };

    if (culture) {
      formCulture = {
        value: culture.CultureName,
        label: culture.Description,
        iconName: culture.FlagCssClassName,
      };
    }

    return {
      dbId: dto.DbId,
      availability: dto.Availability,
      tags: dto.Tags?.map(tag => tag.Name),
      draftId: dto.DraftId,
      selectedCulture: formCulture,
      selectedCategory: DraftUtils.getCategoryByName(dto.SystemName, culture?.CultureName ?? environment.defaultCulture),
      ctName: dto.Name?.Value,
      ctNameLocalized: dto.NameLocalized?.Value,
      ctIsModifiable: DraftUtils.isModifiable(culture?.CultureName ?? environment.defaultCulture).find(x => x.value == dto.IsModifiable),
      ctAbstract: dto.Abstract?.Value,
      ctDescription: dto.Description?.JsonValue ?? dto.Description?.Value,
      ctNameChinese: dto.NameChinese?.Value ?? '',
      ctNameChineseDescription: dto.NameChineseDescription?.JsonValue ?? dto.NameChineseDescription?.Value,
      ctLocation: dto.Location?.JsonValue ?? dto.Location?.Value,
      ctHowToFind: dto.HowToFind?.JsonValue ?? dto.HowToFind?.Value,
      ctActions: dto.Actions?.JsonValue ?? dto.Actions?.Value,
      ctFeatures: dto.Features?.JsonValue ?? dto.Features?.Value,
      ctKind: DraftUtils.getKindByValue(dto.Kind?.Value ?? -1),
      ctKind2nd: DraftUtils.getKinds2ndByValue(dto.Kind2nd?.Value ?? -1),
      ctCharacter: DraftUtils.getCharactersByValue(dto.Character?.Value ?? -1),
      ctElement: DraftUtils.getElementsByValue(dto.Element?.Value ?? -1),
      ctEnergyLevel: DraftUtils.getEnergyLevelsByValue(dto.EnergyLevel?.Value ?? -1),
      ctLimb: DraftUtils.getLimbsByValue(dto.Limb?.Value ?? -1),
      ctActivityTimeMax: DraftUtils.getTimeByValue(dto.ActivityTimeMax?.Value ?? -1),
      ctActivityTimeMin: DraftUtils.getTimeByValue(dto.ActivityTimeMin?.Value ?? -1),
      ctIsDeep: DraftUtils.getIsDeepByValue(dto.IsDeep?.Value ? 0 : 1),
      ctPathway: dto.Pathway?.JsonValue ?? dto.Pathway?.Value,
      ctNameLatin: dto.NameLatin?.JsonValue ?? dto.NameLatin?.Value,
      ctOrigin: dto.Origin?.JsonValue ?? dto.Origin?.Value,
      ctInsertion: dto.Insertion?.JsonValue ?? dto.Insertion?.Value,
      ctNerve: dto.Nerve?.JsonValue ?? dto.Nerve?.Value,
      ctNotes: dto.Notes?.JsonValue ?? dto.Notes?.Value,
      ctAssociatedOrgans: dto.AssociatedOrgans?.JsonValue ?? '',
      ctClientPosition: DraftUtils.getClientPositionsForFormControl(dto.ClientPosition?.Value),
      ctClientPositionMonitoring: dto.ClientPositionMonitoring?.JsonValue ?? dto.ClientPositionMonitoring?.Value,
      ctHandMonitoring: dto.HandMonitoring?.JsonValue ?? dto.HandMonitoring?.Value,
      ctHandStabilizing: dto.HandStabilizing?.JsonValue ?? dto.HandStabilizing?.Value,
      ctMonitoringDirection: dto.MonitoringDirection?.JsonValue ?? dto.MonitoringDirection?.Value,
      ctEmail: dto.Email?.Value ?? '',
      ctFacebook: dto.Facebook?.Value ?? '',
      ctWebsite: dto.Website?.Value ?? '',
      image1: dto.Image01 ?? {
        NewImageFullData: null,
        NewImageSmallData: null,
        NewImageType: '',
        NewImageExtension: '',
        FilePathImageFull: '',
        FilePathImageSmall: '',
        AlternateText: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 1,
      },
      image2: dto.Image02 ?? {
        NewImageFullData: null,
        NewImageSmallData: null,
        NewImageType: '',
        NewImageExtension: '',
        FilePathImageFull: '',
        FilePathImageSmall: '',
        AlternateText: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 2,
      },
      image3: dto.Image03 ?? {
        NewImageFullData: null,
        NewImageSmallData: null,
        NewImageType: '',
        NewImageExtension: '',
        FilePathImageFull: '',
        FilePathImageSmall: '',
        AlternateText: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 3,
      },
      image4: dto.Image04 ?? {
        NewImageFullData: null,
        NewImageSmallData: null,
        NewImageType: '',
        NewImageExtension: '',
        FilePathImageFull: '',
        FilePathImageSmall: '',
        AlternateText: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 4,
      },
      image5: dto.Image05 ?? {
        NewImageFullData: null,
        NewImageSmallData: null,
        NewImageType: '',
        NewImageExtension: '',
        FilePathImageFull: '',
        FilePathImageSmall: '',
        AlternateText: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 5,
      },
      image6: dto.Image06 ?? {
        NewImageFullData: null,
        NewImageSmallData: null,
        NewImageType: '',
        NewImageExtension: '',
        FilePathImageFull: '',
        FilePathImageSmall: '',
        AlternateText: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 6,
      },
      document1: dto.Document01 ?? {
        NewDocumentData: null,
        NewDocumentType: '',
        NewDocumentExtension: '',
        DocumentFilePath: '',
        Description: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 1,
      },
      document2: dto.Document02 ?? {
        NewDocumentData: null,
        NewDocumentType: '',
        NewDocumentExtension: '',
        DocumentFilePath: '',
        Description: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 2,
      },
      document3: dto.Document03 ?? {
        NewDocumentData: null,
        NewDocumentType: '',
        NewDocumentExtension: '',
        DocumentFilePath: '',
        Description: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 3,
      },
      document4: dto.Document04 ?? {
        NewDocumentData: null,
        NewDocumentType: '',
        NewDocumentExtension: '',
        DocumentFilePath: '',
        Description: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 4,
      },
      document5: dto.Document05 ?? {
        NewDocumentData: null,
        NewDocumentType: '',
        NewDocumentExtension: '',
        DocumentFilePath: '',
        Description: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 5,
      },
      document6: dto.Document06 ?? {
        NewDocumentData: null,
        NewDocumentType: '',
        NewDocumentExtension: '',
        DocumentFilePath: '',
        Description: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 6,
      },
      video1: dto.Video01 ?? {
        UploadLink: '',
        Uri: '',
        Link: '',
        Description: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 1,
      },
      video2: dto.Video02 ?? {
        UploadLink: '',
        Uri: '',
        Link: '',
        Description: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 2,
      },
      video3: dto.Video03 ?? {
        UploadLink: '',
        Uri: '',
        Link: '',
        Description: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 3,
      },
      video4: dto.Video04 ?? {
        UploadLink: '',
        Uri: '',
        Link: '',
        Description: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 4,
      },
      video5: dto.Video05 ?? {
        UploadLink: '',
        Uri: '',
        Link: '',
        Description: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 5,
      },
      video6: dto.Video06 ?? {
        UploadLink: '',
        Uri: '',
        Link: '',
        Description: '',
        IsUsed: false,
        DbId: -1,
        Ordinal: 6,
      },
    };
  }

  public static convertDataToHtml(json: string) {
    if (!json) {
      return '';
    }

    const blocks = JSON.parse(json).blocks;

    let convertedHtml = '';
    blocks.map((block: any) => {
      switch (block.type) {
        case 'header':
          convertedHtml += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
          break;
        case 'embed':
          convertedHtml += `<div><iframe width="560" height="315" src="${block.data.embed}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>`;
          break;
        case 'paragraph':
          convertedHtml += `<p>${block.data.text}</p>`;
          break;
        case 'delimiter':
          convertedHtml += '<hr />';
          break;
        case 'image':
          convertedHtml += `<img class="img-fluid" src="${block.data.file.url}" title="${block.data.caption}" /><br /><em>${block.data.caption}</em>`;
          break;
        case 'quote':
          convertedHtml += `<blockquote>${block.data.text}<footer><cite>${block.data.caption}</cite></footer></blockquote>`;
          break;
        case 'list':
          convertedHtml += this.generateListHTML(block.data);
          break;
        case 'table':
          convertedHtml += '<table>';
          for (let i: number = 0; i < block.data.content.length; i++) {
            convertedHtml += `<tr>`;
            if (block.data.withHeadings && i === 0) {
              block.data.content[i].forEach((col: any) => {
                convertedHtml += `<th>${col}</th>`;
              });
            } else {
              block.data.content[i].forEach((col: any) => {
                convertedHtml += `<td>${col}</td>`;
              });
            }
            convertedHtml += `</tr>`;
          }
          convertedHtml += '</table>';
          break;
        default:
          // console.log("Unknown block type", block.type);
          break;
      }
    });
    return convertedHtml;
  }

  private static generateListHTML(data: any): string {
    const listType = data.style === 'unordered' ? 'ul' : 'ol';

    // Recursive function to handle nested lists
    const generateItems = (items: any[]): string => {
      let html = `<${listType}>`;

      items.forEach(item => {
        html += `<li>${item.content}`;

        // If the item has sub-items, recursively generate the nested list
        if (item.items && item.items.length > 0) {
          html += this.generateListHTML({ style: data.style, items: item.items });
        }

        html += `</li>`;
      });

      html += `</${listType}>`;
      return html;
    };

    return generateItems(data.items);
  }
}
