import { SuggestionEmailDto } from "src/app/api/dtos/uok-detail/suggestion-email-dto.interface";
import { BookmarkDto } from '../../api/dtos/uok-detail';
import { UokDetailRepository } from '../../core/repositories';

export class UokDetailRepositoryMock extends UokDetailRepository {

  loadUokContentInfo(uokId: number, lang: string): void {
  }

  loadUokHeader(uokId: number, lang: string): void {
  }

  loadUokLockInfo(uokId: number, lang: string): void {
  }

  loadUokUserInfo(uokId: number, lang: string): void {
  }

  loadUokBookmarkState(uokId: number): void {
  }

  setUokBookmarkState(dto: BookmarkDto): void {
  }

  sendUokSuggestion(dto: SuggestionEmailDto): void {
  }
}
