import { environment } from '../../../environments/environment';
import { ServiceLocator } from '../../core/utils';
import { Store } from '@ngxs/store';
import { GetCultureDefinitionsUseCase } from '../../core/use-cases/utility/get-culture-definitions-use-case.interface';
import { GetCultureDefinitionsUseCaseApp } from './get-culture-definitions-use-case-app.class';
import { GetCultureDefinitionsUseCaseMock } from './get-culture-definitions-use-case-mock.class';

export class UtilityUseCaseFactory {
  private static readonly App = 'app';

  public static GetCultureDefinitions(): GetCultureDefinitionsUseCase {
    if (environment.useCases.utility.cultureDefinitions === this.App) {
      const store = ServiceLocator.injector.get(Store);
      return new GetCultureDefinitionsUseCaseApp(store);
    }
    return new GetCultureDefinitionsUseCaseMock();
  }
}
