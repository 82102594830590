import {IdtoUserFriendInfo} from '../../../../api/dtos/user';
import {ModelBase} from '../../../abstractions';

export interface UserModel extends ModelBase {
  /** the system name of the DTO */
  // systemName: string;
  uokCultureName: string;

  /** Id of the user classified as Operator currently logged in the app */
  operatorId: number;

  /** Database UId of the current model */
  dbId: number;

  /** Culture code of the user */
  cultureName: string;
  username: string;

  state: UserStates;

  // note: string;

  // title: string;

  name: string;

  surname: string;

  companyName: string;

  vatNumber: string;

  sexGender: string;

  birthDate: Date;

  birthCity: string;

  birthProvince: string;

  birthPostalCode: string;

  birthCountry: string;

  phoneMobile: string;

  phoneOffice: string;

  phoneHome: string;

  email: string;

  addressCity: string;

  addressRoad: string;

  addressProvince: string;

  addressPostalCode: string;

  addressCountry: string;

  // fiscalCode1: string;

  // fiscalCode2: string;

  /** See the UserPrivacyStates enum for available values */
  privacyState: number;

  /** Access rights information for the Uok Area */
  uokAccessRights: UokAccessRightsModel[];

  /** The groups which the user belongs */
  // groups: string[];

  /** True if the user has a free account */
  // isLicenceFree: boolean;

  // paymentProvider: string;

  friends: IdtoUserFriendInfo;
  publicNickName: string;

  isLicenseFree: boolean;
  uokAuthorId: number;

  profileImageFull: string;
  profileImageSmall: string;
  contentPolicyAccepted: boolean;
}

export enum UserStates {
  ActiveNoNeedToPay = 0,
  ActiveWithAutomaticRenewal,

  ActiveWithoutAutomaticRenewal,

  Dismissed,

  PaymentIssue
}

export interface UokAccessRightsModel {
  dtoUokSystemName: string;
  canRead: boolean;
  canWrite: boolean;
}
