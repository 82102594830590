import {IDtoDraft} from './dto-draft.interface';
import {IDtoStorableVideo} from './dto-storable-video.interface';
import {IDtoStorableImage} from './dto-storable-image.interface';
import {IDtoStorableDocument} from './dto-storable-document.interface';
import {IDtoStorableHeader} from './dto-storable-header.interface';
import {UokStates} from '../../../core/utils/uok-states.enum';
import {UokDraftStates} from '../../../core/utils';
import { environment } from '../../../../environments/environment';

export function createDraftDtoInterface() {
  const tmp: IDtoDraft = {
    Abstract: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    Actions: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    ActivityTimeMax: {Value: -1, DbId: -1, Ordinal: -1},
    ActivityTimeMin: {Value: -1, DbId: -1, Ordinal: -1},
    AssociatedOrgans: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    Availability: 'Free',
    BoundUserId: -1,
    Character: {Value: -1, DbId: -1, Ordinal: -1},
    ClientPosition: {Value: -1, DbId: -1, Ordinal: -1},
    ClientPositionMonitoring: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    CloneUokId: -1,
    CultureName: environment.defaultCulture,
    DbId: -1,
    Description: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    Document01: createStorableDocumentInterface(1),
    Document02: createStorableDocumentInterface(2),
    Document03: createStorableDocumentInterface(3),
    Document04: createStorableDocumentInterface(4),
    Document05: createStorableDocumentInterface(5),
    Document06: createStorableDocumentInterface(6),
    DraftId: -1,
    DraftProductIds: [],
    DraftState: UokDraftStates.None,
    Element: {Value: -1, DbId: -1, Ordinal: -1},
    Email: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    EnergyLevel: {Value: -1, DbId: -1, Ordinal: -1},
    Facebook: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    Features: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    FirstContributorId: -1,
    HandMonitoring: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    HandStabilizing: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    HasUserActiveLocks: false,
    HowToFind: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    Image01: createStorableImageInterface(1),
    Image02: createStorableImageInterface(2),
    Image03: createStorableImageInterface(3),
    Image04: createStorableImageInterface(4),
    Image05: createStorableImageInterface(5),
    Image06: createStorableImageInterface(6),
    Insertion: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    IsDeep: {Value: false, DbId: -1, Ordinal: -1},
    IsModifiable: false,
    Kind: {Value: -1, DbId: -1, Ordinal: -1},
    Kind2nd: {Value: -1, DbId: -1, Ordinal: -1},
    LastDraftMessage: '',
    Limb: {Value: -1, DbId: -1, Ordinal: -1},
    Location: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    MinutesBeforeLockExpires: 0,
    MonitoringDirection: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    Name: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    NameChinese: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    NameChineseDescription: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    NameLatin: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    NameLocalized: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    Nerve: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    Notes: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    OperatorId: 0,
    Origin: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    Pathway: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    Placeholder: '',
    Position: {Value: -1, DbId: -1, Ordinal: -1},
    State: UokStates.Active,
    SystemName: '',
    Tags: [],
    UserRoles: [],
    Version: 0,
    Video01: createStorableVideoInterface(1),
    Video02: createStorableVideoInterface(2),
    Video03: createStorableVideoInterface(3),
    Video04: createStorableVideoInterface(4),
    Video05: createStorableVideoInterface(5),
    Video06: createStorableVideoInterface(6),
    Website: {Value: '', JsonValue: '', DbId: -1, Ordinal: -1},
    ContentHeader: createStorableHeader(),
    BalanceHeader: createStorableHeader(),
    ReferredBy: {IsEmpty: false, DbId: -1, Ordinal: -1},
    PropertiesA01: {IsEmpty: false, PropertyNames: [], DbId: -1, Ordinal: -1},
    FeaturesHeader: createStorableHeader()
  }

  return tmp;
}

export function createStorableVideoInterface(ordinal: number): IDtoStorableVideo {
  return {UploadLink: '', Uri: '', Link: '', Description: '', IsUsed: false, DbId: -1, Ordinal: ordinal}
}

export function createStorableImageInterface(ordinal: number): IDtoStorableImage {
  return {
    NewImageFullData: null,
    NewImageSmallData: null,
    NewImageType: '',
    NewImageExtension: '',
    FilePathImageFull: '',
    FilePathImageSmall: '',
    AlternateText: '',
    IsUsed: false,
    DbId: -1,
    Ordinal: ordinal
  }
}

export function createStorableDocumentInterface(ordinal: number): IDtoStorableDocument {
  return {
    NewDocumentData: null,
    NewDocumentType: '',
    NewDocumentExtension: '',
    DocumentFilePath: '',
    Description: '',
    IsUsed: false,
    DbId: -1,
    Ordinal: ordinal
  }
}

export function createStorableHeader(): IDtoStorableHeader {
  return {ContentValue: '', DbId: -1, Ordinal: -1}
}
