import { takeUntil } from 'rxjs/operators';
import { ISearchEngineItemDto } from '../../api/dtos/searchengine';
import { SearchApiService } from '../../api/services';
import { RepositoryResponse } from '../../core/abstractions/repositories';
import { SearchRepository } from '../../core/repositories';
import { HttpUtils } from '../../core/utils';
import {
  ISearchEnginePaginatedResultDto,
} from '../../api/dtos/searchengine/search-engine-paginated-result-dto.interface';

export class SearchRepositoryApi extends SearchRepository {
  constructor(private searchApi: SearchApiService) {
    super();
  }

  getByTags(lang: string, queryText: string, withinDto: string[], maxItems: number, page: number): void {
    this.searchApi.getByDtos(lang, queryText, withinDto, maxItems, page)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as ISearchEnginePaginatedResultDto;
          const tmp: RepositoryResponse<ISearchEnginePaginatedResultDto> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SearchEngineDto[] is null' : 'SearchEngineDto[] received',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetByTagsResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<ISearchEnginePaginatedResultDto> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetByTagsResponse(tmp);
        },
      });
  }

  getBookmarksByStatus(status: string, lang: string): void {
    this.searchApi.getBookmarksByStatus(status, lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as ISearchEngineItemDto[];
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SearchEngineDto[] is null' : 'SearchEngineDto[] received',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetBookmarksByStatusResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetBookmarksByStatusResponse(tmp);
        },
      });
  }

  getByName(lang: string, queryText: string, withinDto: string[], maxItems: number): void {
    this.searchApi.getByName(lang, queryText, withinDto, maxItems)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as ISearchEngineItemDto[];
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SearchEngineDto[] is null' : 'SearchEngineDto[] received',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetByNameResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetByNameResponse(tmp);
        },
      });
  }

  getByNameStartsWith(lang: string, queryText: string, withinDto: string[], maxItems: number): void {
    this.searchApi.getByNameStartsWith(lang, queryText, withinDto, maxItems)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as ISearchEngineItemDto[];
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SearchEngineDto[] is null' : 'SearchEngineDto[] received',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetByNameStartsWithResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetByNameStartsWithResponse(tmp);
        },
      });
  }

  getByTagIds(lang: string, includeIds: number[], maxItems: number): void {
    this.searchApi.getByTagIds(lang, includeIds, maxItems)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as ISearchEngineItemDto[];
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SearchEngineDto[] is null' : 'SearchEngineDto[] received',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetByTagIdsResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetByTagIdsResponse(tmp);
        },
      });
  }

  getContributed(lang: string): void {
    this.searchApi.getContributed(lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as ISearchEngineItemDto[];
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SearchEngineDto[] is null' : 'SearchEngineDto[] received',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetContributedResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetContributedResponse(tmp);
        },
      });
  }

  getContributedByUokAuthor(lang: string, uokAuthorId: number): void {
    this.searchApi.getContributedByUokAuthor(lang, uokAuthorId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as ISearchEngineItemDto[];
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SearchEngineDto[] is null' : 'SearchEngineDto[] received',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetContributedByUokAuthorResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetContributedByUokAuthorResponse(tmp);
        },
      });
  }

  getDisabled(lang: string): void {
    this.searchApi.getDisabled(lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as ISearchEngineItemDto[];
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SearchEngineDto[] is null' : 'SearchEngineDto[] received',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetDisabledResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetDisabledResponse(tmp);
        },
      });
  }

  getLatest(lang: string): void {
    this.searchApi.getLatest(lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as ISearchEngineItemDto[];
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SearchEngineDto[] is null' : 'SearchEngineDto[] received',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetLatestResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetLatestResponse(tmp);
        },
      });
  }

  getOperatorsByText(queryText: string): void {
    this.searchApi.getOperatorsByText(queryText)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as ISearchEngineItemDto[];
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SearchEngineDto[] is null' : 'SearchEngineDto[] received',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetOperatorsByTextResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetOperatorsByTextResponse(tmp);
        },
      });
  }

  getPublished(lang: string): void {
    this.searchApi.getPublished(lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as ISearchEngineItemDto[];
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SearchEngineDto[] is null' : 'SearchEngineDto[] received',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetPublishedResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetPublishedResponse(tmp);
        },
      });
  }

  getSignaled(lang: string): void {
    this.searchApi.getSignaled(lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as ISearchEngineItemDto[];
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SearchEngineDto[] is null' : 'SearchEngineDto[] received',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetSignaledResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<ISearchEngineItemDto[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setGetSignaledResponse(tmp);
        },
      });
  }
}
