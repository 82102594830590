import { UseCaseActionName } from 'src/app/core/utils';
import { GetCultureDefinitionsUseCase } from '../../core/use-cases/utility/get-culture-definitions-use-case.interface';
import { GetCultureDefinitionsUseCaseApp } from './get-culture-definitions-use-case-app.class';

export class GetCultureDefinitionsUseCaseMock implements GetCultureDefinitionsUseCase {
  init(): void {
    throw new Error('Method not implemented.');
  }

  execute(action: UseCaseActionName, params?: null | undefined): void {
    throw new Error('Method not implemented.');
  }

  getName(): string {
    return GetCultureDefinitionsUseCaseApp.Name;
  }

  dispose(): void {
    throw new Error('Method not implemented.');
  }

}
