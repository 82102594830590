import { StripeRepository } from '../../core/repositories';
import { StripeApiService } from '../../api/services/stripe-api.service';
import { takeUntil } from 'rxjs/operators';
import { RepositoryResponse, RepositoryResponseGeneric } from '../../core/abstractions/repositories';
import { HttpUtils } from '../../core/utils';
import { StripeService } from 'ngx-stripe';
import { StripeElements } from '@stripe/stripe-js';
import {
  DtoStripeCustomerGetRequest,
  DtoStripeCustomerGetResponse,
  DtoStripeSetupIntentGetRequest,
  DtoStripeSetupIntentGetResponse,
  DtoStripeSubscriptionApplyAccessKeyRequest,
  DtoStripeSubscriptionApplyAccessKeyResponse,
  DtoStripeSubscriptionCreateRequest,
  DtoStripeSubscriptionCreateResponse, IDtoStripeData,
  IDtoStripePaymentMethodUpdateFinalizeRequest, IDtoStripePaymentMethodUpdateFinalizeResponse,
} from '../../api/dtos/stripe';

export class StripeRepositoryApi extends StripeRepository {
  constructor(private stripeApi: StripeApiService, private stripeService: StripeService) {
    super();
  }

  public override paymentConfirm(stripeElements: StripeElements, returnUrl: string, clientSecret: string | undefined): void {
    this.setIsUpdating(true);
    this.stripeService.confirmPayment({
      elements: stripeElements,
      clientSecret: clientSecret ?? '',
      confirmParams: {
        return_url: returnUrl,
      },
    }).pipe(takeUntil(this.destroy$))
      .subscribe({
          next: result => {
            this.setIsUpdating(false);
            if (result?.error) {
              this.setPaymentConfirmResponse({ dto: { error: result.error }, info: null });
            } else {
              this.setPaymentConfirmResponse({ dto: { error: undefined }, info: null });
            }
            // console.log("NEXT: " + JSON.stringify(result));
            // this.setPaymentConfirmResponse({dto: {error: result}, info: null});
          },
          error: err => {
            console.log('ERR: ' + JSON.stringify(err));
            this.setIsUpdating(false);
            this.setPaymentConfirmResponse({ dto: { error: err }, info: null });
          },
        },
      );
  }

  public override setupIntentConfirm(stripeElements: StripeElements, returnUrl: string, clientSecret: string | undefined) {


    this.stripeService.confirmSetup({
      elements: stripeElements,
      clientSecret: clientSecret ?? '',
      confirmParams: {
        return_url: returnUrl,
      },
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: result => {
            console.log('NEXT: ' + JSON.stringify(result));
            this.setIsUpdating(false);

            if (result.error) {
              this.setSetupIntentConfirmResponse({ dto: { error: result.error }, info: null });
            } else {
              this.setSetupIntentConfirmResponse({ dto: { error: undefined }, info: null });
            }
          },
          error: err => {
            console.log('ERR: ' + JSON.stringify(err));
            this.setIsUpdating(false);
            this.setSetupIntentConfirmResponse({ dto: { error: err }, info: null });
          },
        },
      );
  }

  public override setupIntentGet(dto: DtoStripeSetupIntentGetRequest): void {
    this.stripeApi.setupIntentGet(dto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: data => {
            const body = data as DtoStripeSetupIntentGetResponse;
            const tmp: RepositoryResponse<DtoStripeSetupIntentGetResponse> = {
              dto: body ?? null,
              info: {
                level: !body ? 1 : 0,
                messages: [
                  !body ? 'StripeSetupIntentDto is null' : 'StripeSetupIntentDto received',
                ],
              },
            };
            this.setIsUpdating(false);
            this.setSetupIntentGetResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponse<DtoStripeSetupIntentGetResponse> = {
              dto: null,
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err),
                ],
              },
            };
            this.setIsUpdating(false);
            this.setSetupIntentGetResponse(tmp);
          },
        },
      );
  }

  public override customerGet(dto: DtoStripeCustomerGetRequest): void {
    this.stripeApi.customerGet(dto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: data => {
            const body = data as DtoStripeCustomerGetResponse;
            const tmp: RepositoryResponse<DtoStripeCustomerGetResponse> = {
              dto: body ?? null,
              info: {
                level: !body ? 1 : 0,
                messages: [
                  !body ? 'DtoStripeCustomerGetResponse is null' : 'DtoStripeCustomerGetResponse received',
                ],
              },
            };
            this.setIsUpdating(false);
            this.setCustomerGetResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponse<DtoStripeCustomerGetResponse> = {
              dto: null,
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err),
                ],
              },
            };
            this.setIsUpdating(false);
            this.setCustomerGetResponse(tmp);
          },
        },
      );
  }

  public override subscriptionCreate(dto: DtoStripeSubscriptionCreateRequest): void {
    this.stripeApi.subscriptionCreate(dto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: data => {
            const body = data as DtoStripeSubscriptionCreateResponse;
            const tmp: RepositoryResponse<DtoStripeSubscriptionCreateResponse> = {
              dto: body ?? null,
              info: {
                level: !body ? 1 : 0,
                messages: [
                  !body ? 'DtoStripeSubscriptionCreateResponse is null' : 'DtoStripeSubscriptionCreateResponse received',
                ],
              },
            };
            this.setIsUpdating(false);
            this.setSubscriptionCreateResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponse<DtoStripeSubscriptionCreateResponse> = {
              dto: null,
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err),
                ],
              },
            };
            this.setIsUpdating(false);
            this.setSubscriptionCreateResponse(tmp);
          },
        },
      );
  }

  public override subscriptionApplyAccessKey(dto: DtoStripeSubscriptionApplyAccessKeyRequest): void {
    this.stripeApi.subscriptionApplyAccessKey(dto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: data => {
            const body = data as DtoStripeSubscriptionApplyAccessKeyResponse;
            const tmp: RepositoryResponse<DtoStripeSubscriptionApplyAccessKeyResponse> = {
              dto: body ?? null,
              info: {
                level: !body ? 1 : 0,
                messages: [
                  !body ? 'DtoStripeSubscriptionApplyAccessKeyResponse is null' : 'DtoStripeSubscriptionApplyAccessKeyResponse received',
                ],
              },
            };
            this.setIsUpdating(false);
            this.setSubscriptionApplyAccessKeyResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponse<DtoStripeSubscriptionApplyAccessKeyResponse> = {
              dto: null,
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err),
                ],
              },
            };
            this.setIsUpdating(false);
            this.setSubscriptionApplyAccessKeyResponse(tmp);
          },
        },
      );
  }

  updatePaymentMethodFinalize(dto: IDtoStripePaymentMethodUpdateFinalizeRequest): void {
    this.stripeApi.paymentMethodUpdateFinalize(dto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: data => {
            const body = data as IDtoStripePaymentMethodUpdateFinalizeResponse;
            const tmp: RepositoryResponse<IDtoStripePaymentMethodUpdateFinalizeResponse> = {
              dto: body ?? null,
              info: {
                level: !body ? 1 : 0,
                messages: [
                  !body ? 'IDtoStripePaymentMethodUpdateFinalizeResponse is null' : 'IDtoStripePaymentMethodUpdateFinalizeResponse received',
                ],
              },
            };
            this.setIsUpdating(false);
            this.setUpdatePaymentMethodFinalizeResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponse<IDtoStripePaymentMethodUpdateFinalizeResponse> = {
              dto: null,
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err),
                ],
              },
            };
            this.setIsUpdating(false);
            this.setUpdatePaymentMethodFinalizeResponse(tmp);
          },
        },
      );
  }

  userResubscribe(): void {
    this.stripeApi.resubscribe()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: () => {
            const tmp: RepositoryResponseGeneric = {
              info: {
                level: 0,
                messages: [
                  'UserCashInfoDto received',
                ],
              },
            };
            this.setIsUpdating(false);
            this.setUserResubscribeResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponseGeneric = {
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err),
                ],
              },
            };
            this.setIsUpdating(false);
            this.setUserResubscribeResponse(tmp);
          },
        },
      );
  }

  userUnsubscribe(): void {
    this.stripeApi.unsubscribe()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: () => {
            const tmp: RepositoryResponseGeneric = {
              info: {
                level: 0,
                messages: [
                  'UserCashInfoDto received',
                ],
              },
            };
            this.setIsUpdating(false);
            this.setUserUnsubscribeResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponseGeneric = {
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err),
                ],
              },
            };
            this.setIsUpdating(false);
            this.setUserUnsubscribeResponse(tmp);
          },
        },
      );
  }

  getUserStripeInfo(lang: string): void {
    this.stripeApi.getInfo(lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: data => {
            const body = data as IDtoStripeData;
            const tmp: RepositoryResponse<IDtoStripeData> = {
              dto: body ?? null,
              info: {
                level: !body ? 1 : 0,
                messages: [
                  !body ? 'UserDto is null' : 'UserDto received',
                ],
              },
            };
            this.setIsUpdating(false);
            this.setStripeInfoResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponse<IDtoStripeData> = {
              dto: null,
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err),
                ],
              },
            };
            this.setIsUpdating(false);
            this.setStripeInfoResponse(tmp);
          },
        },
      );
  }
}
