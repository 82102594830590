import {commonEnvironment} from './environment.common';

const env: Partial<typeof commonEnvironment> = {
  appRevision: commonEnvironment.appRevision + '-STAGING',
  environmentName: 'staging',
  webApi: 'https://k-test.knowlative.com/api/',
  webApiV1: 'https://k-test.knowlative.com/api/v1.0.0/',
  webApp: 'https://k-test.knowlative.com/app/#/',
  stripe:{
    key: 'pk_test_XOzU5EwnSj0vUyVgPE9uYZHS', //  Official Stripe Account (administration@knowlative.com)
    returnUrlOnConfirmation: 'https://k-test.knowlative.com:49152/', //redirect website to pass the parameters to the signup
    defaultPlanPrice: 24, //in eur used to setup the stripe payment element component
    paymentMethodTypes: ['card'] // stripe payment element default payment methods
  },
  services: {
    authRepository: 'api',
    userRepository: 'api',
    accessKeyRepository: 'api',
    activityCenter: 'app',
    translateConfig: 'app',
    productRepository: 'api',
    surveyRepository: 'api',
    searchEngineRepository: 'api',
    uokDetailRepository: 'api',
    uokLinkRepository: 'api',
    draftRepository:'api',
    stripeRepository: 'api',
    utilityRepository: 'api'
  },
  useCases: {
    auth: {
      login: 'app'
    },
    products: 'app',
    survey: 'app',
    searchEngine: 'app',
    uokDetail: 'app',
    draft:'app',
    stripe:{
      createSubscription:'app',
      confirmPayment: 'app',
      getSetupIntent: 'app',
      getCustomer:'app',
      confirmSetupIntent: 'app',
    },
    utility: {
      cultureDefinitions: 'app',
    }
  },
  matomo :{
    trackerUrl: 'https://k-develop.knowlative.com/matomo',
    siteId: '2',
  }
};

export const environment = {...commonEnvironment, ...env};
