import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {
  DtoStripeCustomerGetRequest,
  DtoStripeSetupIntentGetRequest,
  DtoStripeSubscriptionApplyAccessKeyRequest,
  DtoStripeSubscriptionCreateRequest,
  IDtoStripePaymentMethodUpdateFinalizeRequest
} from "../dtos/stripe";

@Injectable({
  providedIn: 'root'
})
export class StripeApiService {
  constructor(private httpClient: HttpClient) {
  }

  public setupIntentGet(dto: DtoStripeSetupIntentGetRequest) {
    return this.httpClient.post(`${environment.webApiV1}${environment.defaultCulture}/stripe/get-setup-intent`, JSON.stringify(dto));
  }

  public customerGet(dto: DtoStripeCustomerGetRequest) {
    return this.httpClient.post(`${environment.webApiV1}${environment.defaultCulture}/stripe/get-customer`, JSON.stringify(dto));
  }

  public subscriptionCreate(dto: DtoStripeSubscriptionCreateRequest) {
    return this.httpClient.post(`${environment.webApiV1}${environment.defaultCulture}/stripe/create-subscription`, JSON.stringify(dto));
  }

  public subscriptionApplyAccessKey(dto: DtoStripeSubscriptionApplyAccessKeyRequest) {
    return this.httpClient.post(`${environment.webApiV1}${environment.defaultCulture}/stripe/subscription-apply-accesskey`, JSON.stringify(dto));
  }

  public paymentMethodUpdateFinalize(dto: IDtoStripePaymentMethodUpdateFinalizeRequest) {
    return this.httpClient.post(`${environment.webApiV1}${environment.defaultCulture}/stripe/payment-method-update-finalise`, JSON.stringify(dto));
  }

  public getInfo(lang: string) {
    return this.httpClient.get(`${environment.webApiV1}${lang}/stripe/info`);
  }

  public unsubscribe() {
    return this.httpClient.put(`${environment.webApiV1}${environment.defaultCulture}/stripe/unsubscribe-from-pro`, '');
  }

  public resubscribe() {
    return this.httpClient.put(`${environment.webApiV1}${environment.defaultCulture}/stripe/resubscribe-to-pro`, '');
  }

  public removeSubscription() {
    return this.httpClient.put(`${environment.webApiV1}${environment.defaultCulture}/stripe/delete-stripe-subscription`, '');
  }
}
